<template>
  <div class="Login">
    <q-form class="fixed-center shadow-2" @submit="onSubmit">
      <div class="title text-center text-white text-h6 bg-grey-5">
        Acesso ao sistema
      </div>
      <div class=" q-pa-md">
        <q-input v-model="form.email" type="email" label="E-Mail" />
        <q-checkbox dense size="xs" v-model="keepEmail" label="Manter email" />
        <q-input
          v-model="form.password"
          :type="isPwd ? 'password' : 'text'"
          label="Senha"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>
        <div
          class="text-right cursor-pointer text-blue"
          @click="onResetPassword"
        >
          <span>Esqueci minha senha</span>
        </div>

        <div class="row justify-between q-mt-md q-mx-md">
          <q-btn
            v-if="false"
            class="col-6 text-caption "
            label="Cadastre-se"
            color="primary"
            @click="signUp"
          />
          <q-btn
            class="offset-8 col-4"
            label="Entrar"
            type="submit"
            color="positive"
          />
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import { signIn, resetPassword } from "@/services/Auth";
export default {
  data() {
    return {
      keepEmail: false,
      form: {
        email: null,
        password: null
      },
      isPwd: true
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.$q.loading.show({
          message:
            'Acessando o sistema.<br/><span class="text-primary">Aguarde...</span>'
        });
        await signIn(this.form);
        if (this.keepEmail) {
          window.localStorage.setItem("signInMail", this.form.email);
        } else {
          window.localStorage.removeItem("signInMail");
        }
        this.$router.push({ name: "Home" });
      } catch (error) {
        this.$q.notify({
          color: "red",
          textColor: "white",
          message: "Usuário e/ou senha inválido(s)",
          icon: "announcement",
          position: "top"
        });
      }
      this.$q.loading.hide();
    },
    signUp() {
      this.$router.push({ name: "SignUp" });
    },
    async onResetPassword() {
      if (!this.form.email) {
        this.$q.notify({
          color: "red",
          textColor: "white",
          message: "Digite o seu e-mail",
          icon: "announcement",
          position: "top"
        });
        return;
      }
      try {
        await resetPassword(this.form.email);
        this.$q.notify({
          color: "green",
          textColor: "white",
          message: "E-mail para recuperação de senha enviado com sucesso!",
          icon: "announcement",
          position: "top"
        });
      } catch (error) {
        this.$q.notify({
          color: "orange",
          textColor: "white",
          message:
            "Não foi possivel enviar o email para recuperação da senha. Você tem certeza que digitou o e-mail correto?",
          icon: "announcement",
          position: "top"
        });
      }
    }
  },
  created() {
    const email = window.localStorage.getItem("signInMail");
    if (email) {
      this.form.email = email;
      this.keepEmail = true;
    }
  }
};
</script>

<style lang="scss">
.Login {
  width: 100%;
  height: 100%;
  .q-form {
    width: 85vw;
    max-width: 300px;
  }
}
</style>
